@media (max-width: 1399px) {
  .adaptive-page-route > .MuiGrid-grid-xs-3 > *{
    padding: 16px;
  }
  .adaptive-page-route .MuiFormControlLabel-label{
    font-size: 14px;
  }

  .adaptive-page-route__information-marker{
    padding: 0!important;
  }

  .adaptive-page-route__information-marker-content{
    padding: 10px 16px!important;
  }
}