@font-face {
  font-family: 'Graphik-LCG';
  src: url('../fonts/GraphikLCG-Extralight.woff2') format('woff2'),
  url('../fonts/GraphikLCG-Extralight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Graphik-LCG';
  src: url('../fonts/GraphikLCG-Thin.woff2') format('woff2'),
  url('../fonts/GraphikLCG-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Graphik-LCG';
  src: url('../fonts/GraphikLCG-Bold.woff2') format('woff2'),
  url('../fonts/GraphikLCG-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Graphik-LCG';
  src: url('../fonts/GraphikLCG-Medium.woff2') format('woff2'),
  url('../fonts/GraphikLCG-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Graphik-LCG';
  src: url('../fonts/GraphikLCG-Semibold.woff2') format('woff2'),
  url('../fonts/GraphikLCG-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Graphik-LCG';
  src: url('../fonts/GraphikLCG-Regular.woff2') format('woff2'),
  url('../fonts/GraphikLCG-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Graphik-LCG';
  src: url('../fonts/GraphikLCG-Light.woff2') format('woff2'),
  url('../fonts/GraphikLCG-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Graphik-LCG';
  src: url('../fonts/GraphikLCG-Black.woff2') format('woff2'),
  url('../fonts/GraphikLCG-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
