.reference{
  ul, ol{
    padding-left: 16px;
  }
  ul, ol{
    li {
      margin-bottom: 8px;
    }
  }

  ul, ol{
    ul, ol{
      margin: 8px 0;
      padding-left: 32px;
    }
  }
}
