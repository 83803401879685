.router-switch-style{
  display: inline-block!important;

  @supports (display: flex) or (display: -webkit-flex) {
    display: inline-flex!important;
  }
}
.router-body-fixed-style{
  width: 100%!important;
}
.router-view-map{
  min-height: calc(100vh - 200px);
}