* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #f4f6f8;
  height: 100%;
  font-family: "Graphik-LCG", "Roboto", "Helvetica", "Arial", sans-serif;
}

a {
  text-decoration: none;
}

img, image{
  max-width: 100%;
}

.notification-parent{
  font-family: "Graphik LCG", "Roboto", "Helvetica", "Arial", sans-serif;
}

#root {
  height: 100%;
}

.height-auto{
  height: auto;
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]{
  padding-right: 35px!important;
}


.MuiRadio-colorSecondary.Mui-checked{
  color: #212529!important;
}

.scrollbar-container{
  height: auto;
}

.flex{
  display: flex;
}
.flex-col{
  flex-direction: column;
}
