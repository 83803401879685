.leaflet-marker-icon.marker-cluster div{
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #FFFFFF;

  background-color: rgba(41, 121, 255, 0.6);
}
.leaflet-marker-icon.marker-cluster.marker-cluster-small {
  background-color: rgba(41, 121, 255, 0.6);
}